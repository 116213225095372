//Media query manager
/*
Ranges:
    0 - 300px: small phone
    300 - 600px: Phone
    600 - 900px: Tablet portrait
    900 - 1200px: tablet landscape
    1200 - 1800px: normal styles
    1800px+: big desktop
*/

/*
$breakpoint args:
    - small-phone 
    - phone
    - tab-port
    - tab-land
    - big-desk
*/
@mixin respond($breakpoint) {
    @if $breakpoint == small-phone {
        @media (max-width: 18.75em) { @content }; //300px
    }

    @if $breakpoint == phone {
        @media (max-width: 37.5em) { @content }; //600px
    }
    @if $breakpoint == tab-port {
        @media (max-width: 56.25em) { @content }; //900px
    }
    @if $breakpoint == tab-land {
        @media (max-width: 75em) { @content }; //1200px
    }
    @if $breakpoint == big-desk {
        @media (min-width: 112.5em) { @content }; //1800px
    }
}