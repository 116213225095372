//COLORS
$primary-color: #15a246; //#50a406
$secondary-color: #272727;
$dark-gray: #11101d;
$white: #fff;
$light-white: #f2f2ee;
$light-gray: #f5f5f5;
$yellowish-white: #fff5c3;

//SIZES
//Header
$header-height: 35rem;

//Services list items
$navbar-width: 24rem;
$list-items-width: 70rem;
$list-item-width-small-phone: 40rem;
$list-item-width-medium-phone: 50rem;
$list-items-height: 15rem;
$list-items-margin: 6rem;
$list-items-font-size: 2rem;
$img-height: 25rem;
$img-width: 40rem;

//Details item
$details-item-width: 40rem;
$details-item-height: 40rem;

//Footer
$footer-height: 35rem;