@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

.services {
    margin-top: 4rem;
    width: 100%;
    color: $dark-gray;
    padding-left: 0;
    text-align: center;

    &__list {
        width: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    // &__images {
    //     list-style: none;
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    // }

    &__title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-family: 'Allura', sans-serif;
        font-size: 10rem;
    }
}


.add-service {
    position: relative;

    &:hover .add-service__tooltip {
        visibility: visible;
    }

    &__icon {
        display: flex;
        font-size: 6rem;
        color: $primary-color;
    }

    &__tooltip {
        visibility: hidden;
        padding: 1rem;
        min-width: 15rem;
        position: absolute;
        background-color: $yellowish-white;
        border-radius: 5px;
        transform: translateX(-2rem);

        &-text {
            font-size: 1.5rem;
            text-align: left;
        }
    }
}
