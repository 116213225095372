@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

.footer {
    width: 100%;
    height: $footer-height;
    background-color: $primary-color;
    position: relative;
    box-shadow: 0 -10px 15px -3px rgb(0 0 0 / 0.1), 0 -4px 6px -4px rgb(0 0 0 / 0.1);

    &__wrapper {
        display: flex;
        justify-content: space-around;
        flex-direction: row;

        @include respond(small-phone) {
            // flex-direction: row;
        }
    }

    &__logo-container {
        margin-bottom: 4rem;
    }

    &__logo {
        font-family: 'Belleza';
        color: #fff;
        font-size: 2.5rem;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 1rem;
        position: absolute;
        top: 3rem;
        left: 3rem;
    }

    &__section-container {
        position: absolute;
        top: 30%;
        left: 3rem;
    }

    &__section-links {
        display: flex;
        gap: 15rem;

        @include respond(tab-port) {
            display: flex;
            flex-direction: column;
            gap: 0;
        }

        @include respond(phone) {
            display: flex;
            flex-direction: column;
            gap: 0;
        }
    }

    &__section-double {
        margin-top: 1.6rem;

        @include respond(tab-port) {
            margin-top: 0;
        }

        @include respond(phone) {
            margin-top: 0;
        }
    }

    &__section-title {
        color: #f1997e;
        font-size: 3rem;
        font-family: "Montserrat", "sans-serif";
        letter-spacing: 0.2rem;
        margin-bottom: 1.5rem;
    }

    &__schedule-container {
        position: absolute;
        top: 30%;
        right: 8%;

        @include respond(tab-land) {
            right: 5%;
        }
    }

    &__schedule-title {
        color: #f1997e;
        font-size: 2.5rem;
        font-family: "Montserrat", "sans-serif";
        letter-spacing: 0.2rem;
        margin-bottom: 1.5rem;
    }

    &__schedule-list {
        list-style-type: none;
    }

    &__schedule-item {
        color: #fff;
        font-family: "Montserrat", "sans-serid";
        font-weight: 400;
        font-size: 1.4rem;
        margin-top: 1rem;

        @include respond(phone) {
            font-size: 1.2rem;
        }
    }
}

.footer-item {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    @include respond(phone) {
        margin-top: 0;
        margin-bottom: 0.5rem;
    }

    &__title {
        color: #fff;
        font-family: "Montserrat", "sans-serid";
        font-weight: 400;
        font-size: 1.8rem;
        margin-left: 1rem;

        @include respond(phone) {
            font-size: 1.5rem;
        }
    }
}