@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

.add-post {
    &__container {
        padding: 0 5rem;
    }

    &__description {
        margin: 10rem 0;
        text-align: center;

        &-title {
            display: inline-block;
            font-family: 'Allura', sans-serif;
            font-size: 8rem;
            color: $secondary-color;
            margin-bottom: 2rem;
        }
    }
}

.post-form {
    &__wrapper {
        display: flex;
        justify-content: space-between;

        @include respond(tab-land) {
            flex-direction: column;
        }
    }
    

    &__form {
        &-title {
            font-size: 3rem;
            font-weight: 400;
            margin-bottom: 10rem;
            display: inline-block;

            &:after {
                content: '';
                display: block;
                position: relative;
                width: 40%;
                border: 2px solid $primary-color;
                margin-top: 1rem;
            }
        }

        &-fields {
            margin-left: 3rem;
        }

        @include respond(tab-land) {
            margin-bottom: 20rem;
        }

        input[type="file"] {
            display: none;
        }
    }


    &__file-upload {
        cursor: pointer;
        font-size: 2rem;
        display: flex;
        align-items: center;
        margin-bottom: 5rem;
        
        &-icon {
            color: $primary-color;
            display: flex;
            align-items: center;
            font-size: 4rem;
            margin-right: 1rem;
        }
    }

    &__button-wrapper {
        margin-top: 7rem;
        margin-left: 3rem;
        display: flex;
        align-items: center;
    }
    

    &__preview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        margin-bottom: 10rem;

        &-wrapper:not(:last-child) {
            margin-bottom: 10rem;
        }

        &-title-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 5rem;
        }

        &-title-icon {
            font-size: 3rem;
            color: $primary-color;
            cursor: pointer;
            margin-left: 2rem;
            display: flex;
        }

        &-title {
            font-size: 3rem;
            font-weight: 400;

            &:after {
                content: '';
                display: block;
                position: relative;
                width: 40%;
                border: 2px solid $primary-color;
                margin-top: 1rem;
            }
        }
    }
}
