@import "sassStyles/mixins";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  position: relative;
  font-family: 'Montserrat', 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;

  @include respond(tab-land) {
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    font-size: 50%;
  }

  @include respond(phone) {
    font-size: 40%;
  }

  @include respond(small-phone) {
    font-size: 33%;
  }

  @include respond(big-desk) {
    font-size: 75%;
  }
}

a {
  text-decoration: none;
  color: black;
}
