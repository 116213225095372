@import '../../sassStyles/variables';

.service-details {
    width: $details-item-width;
    background-color: $light-gray;
    padding-bottom: .1rem;

    &__image {
        width: 100%;
        max-width: $details-item-width;
        object-fit: cover;
        object-position: center;
    }

    &__title-container {
        width: 80%;
        border-bottom: 3px solid $primary-color;
        margin: 2rem 1rem;
        padding: 1.2rem 0;
    }

    &__title {
        font-family: 'Montserrat', 'Lato', sans-serif;
        font-weight: 500;
        font-size: 3rem;
        line-height: 3rem;
        letter-spacing: 0.2rem;
    }

    &__text {
        color: $dark-gray;
        font-size: 1.5rem;
        font-family: 'Montserrat', sans-serif;
        margin: 0.5rem 1rem;
        white-space: pre-line;

        &:last-child {
            margin-bottom: 2.5rem;
        }
    }

    &__button-container {
        margin: 3rem 0 2rem 2rem;
    }
}
