@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

.post {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50rem;
    padding: 6rem 0;
    position: relative;

    @include respond(tab-land) {
        min-height: 40rem;
    }

    &__image-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    &__image {
        max-height: 35rem;
        max-width: 60rem;
        border-radius: 10px;

        @include respond(tab-land) {
            max-width: 40rem;
        }
    }

    &__title-wrapper {
        margin-bottom: 4rem;
    }


    &__title {
        display: inline-block;
        font-size: 4rem;
        font-family: "Belleza", sans-serif;

        &:after {
            content: '';
            display: block;
            // position: relative;
            width: 100%;
            border: 2px solid $primary-color;
            margin-top: 1rem;
        }
    }


    &__description {
        margin-top: 4rem;

        @include respond(tab-land) {
            margin: 0 10rem;
        }
    }

    &__text {
        font-family: "Montserrat", sans-serif;
        margin-top: 2rem;
        font-size: 1.4rem;
        white-space: pre-line;
        line-height: 2rem;
    }

    .actions-container {
        position: absolute;
        right: 1rem;
        top: 1rem;
        
        background-color: $secondary-color;
        color: $primary-color;
        font-size: 3rem;
        border-radius: 15px;

        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .delete-container {
        display: flex;
        cursor: pointer;
    }
}