@import '../../sassStyles/variables';
@import '../../sassStyles/mixins';

$navBarHeightPhone: 10rem;
$navPadding: 3rem;

.nav {
    padding: $navPadding;
    background-color: $secondary-color;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    position: relative;
    font-family: "Belleza", sans-serif;

    &__img {
        max-height: $navBarHeightPhone;
    }

    &__links-container {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__links--desktop {
        font-size: 2rem;
        font-weight: 400;

        @include respond(tab-land) {
            display: none;
          }
    }

    &__links {
        height: 100%;
        display: inline-flex;
        flex-wrap: wrap;
        gap: 5rem
    }

    &__link {
        cursor: pointer;
        text-transform: uppercase;
        color: $white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // margin-right: 5rem;
    }

    &__burger-menu {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        width: 3.8rem;
        height: 3.8rem;
  
        @include respond(tab-land) {
          display: flex;
        }
  
        .bar {
            width: 100%;
            background-color: $primary-color;
            border: 1px solid $primary-color;
            border-radius: 10px;
        }
  
        .close-bar {
            width: 100%;
            height: 2px;
            background-color: $primary-color;
            border-radius: 10px;
            transform: rotate(135deg);
            position: absolute;
            top: 50%;
        }
  
        .close-bar-bottom {
            width: 100%;
            height: 2px;
            background-color: $primary-color;
            border-radius: 10px;
            transform: rotate(-135deg);
            position: absolute;
            top: 50%;
        }
      }

  
      &__menu-open {
        position: absolute;
        top: calc($navBarHeightPhone + 2*$navPadding - 10%);
        left: 0;
        width: 100%;
        background-color: $secondary-color;
        padding: 3rem;

        z-index: 99999;
      }
      
      &__links-container--phone {
        font-size: 1.8rem;
      }
}